import React from "react";

import { validateType } from "@util/validateType";
import { BlocksContent, Button, Image, ParallaxColumns } from "@global";

interface Props {
  data: any;
}

const Slices = ({ data }: Props) => {
  if (!data) return null;

  return (
    <section className="slices-wrapper">
      {data?.map((slice: any, index: number) => {
        if (validateType.isBlockContent(slice)) {
          return <BlocksContent data={slice} key={index} />;
        }
        if (validateType.isImageWithMeta(slice)) {
          return <Image data={slice} key={index} />;
        }
        if (validateType.isCards(slice)) {
          return <ParallaxColumns data={slice.items!} key={index} />;
        }
        if (validateType.isLink(slice)) {
          return <Button key={index} {...slice} />;
        }

        return <p>No slice data</p>;
      })}
    </section>
  );
};

export default Slices;
