import React from "react";
import { PageProps, graphql } from "gatsby";

import Blocks from "@components/blocks";
import { Hero } from "@global";
import { Query } from "@graphql-types";
import Layout from "@shared/layout";
import SEO from "@shared/seo";

interface Props extends PageProps {
  data: Query;
  pageContext: { countryCode: string; slug: string };
}

const PageTemplate = ({ data }: Props) => {
  if (data.sanityPage == null) return null;
  const { seo, hero, blocks } = data.sanityPage;

  return (
    <>
      <SEO seoData={seo} />
      <Layout hideFooterCtas>
        <Hero data={hero} />
        {blocks && <Blocks blocks={blocks} />}
      </Layout>
    </>
  );
};

export default PageTemplate;

export const query = graphql`
  query pageQuery($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
      blocks {
        ... on SanityHero {
          ...sanityHero
        }
        ... on SanityImageWithMeta {
          ...sanityFullWidthImage
        }
        ... on SanityColumnContent {
          ...sanityColumnContent
        }
        ... on SanityInformationColumns {
          ...sanityInformationColumns
        }
        ... on SanityVideo {
          ...sanityVideo
        }
        ... on SanityListBlock {
          ...sanityListBlock
        }
        ... on SanityFeaturedCollections {
          ...sanityFeaturedCollections
        }
        ... on SanityThemeSelector {
          ...sanityThemeSelector
        }
        ... on SanityTestimonialsSlider {
          ...sanityTestimonialsSlider
        }
        ... on SanityContainer {
          ...sanityContainer
        }
        ... on SanityForm {
          ...sanityForm
        }
      }
    }
  }
`;
