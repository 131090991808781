import React from "react";

import {
  ColumnContent,
  Hero,
  Image,
  InformationColumns,
  ListBlock,
  TestimonialsSlider,
  Video,
} from "@global";
import { validateType } from "@util/validateType";
import ContainerBlock from "./containerBlock";
import Form from "./forms/form";

interface Props {
  blocks: any;
}

const Blocks = ({ blocks }: Props) => {
  if (!blocks) return null;

  return (
    <section className="blocks-wrapper">
      {blocks?.map((block: any, index: number) => {
        if (validateType.isHero(block)) {
          return <Hero data={block} key={index} />;
        }
        if (validateType.isImageWithMeta(block)) {
          return <Image data={block} key={index} />;
        }
        if (validateType.isColumnContent(block)) {
          return <ColumnContent data={block} key={index} />;
        }
        if (validateType.isInformationColumns(block)) {
          return <InformationColumns data={block} key={block._key} />;
        }
        if (validateType.isVideo(block)) {
          return (
            <Video
              controls
              url={block.url}
              thumbnail={block.thumbnail}
              key={block._key}
              title={block.videoTitle}
            />
          );
        }
        if (validateType.isListBlock(block)) {
          return <ListBlock data={block} key={block._key} />;
        }
        if (validateType.isTestimonialsSlider(block)) {
          return <TestimonialsSlider data={block} key={block._key} />;
        }
        if (validateType.isContainerBlock(block)) {
          return <ContainerBlock data={block} key={block._key} />;
        }
        if (validateType.isForm(block)) {
          return <Form data={block} key={block._key} />;
        }

        return <p>No block found</p>;
      })}
    </section>
  );
};

export default Blocks;
