import React from "react";
import styled from "styled-components";
import JotformEmbed from "react-jotform-embed";

import { BlocksContent } from "@global";

import { Container } from "@util/standard";
import { StandardLayout } from "../../global/global.styles";
import { SanityForm } from "@graphql-types";
import { getJotformUrl } from "@util/helper";

interface Props {
  data: SanityForm;
}

const TextWrapper = styled(Container)`
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0px;
  }
`;

function Form({ data }: Props) {
  if (!data) return null;

  const formUrl = getJotformUrl(data.jotformId!);
  return (
    <StandardLayout margin="150px auto" tabletMargin="70px auto" columnGap={20}>
      {data.formText && (
        <TextWrapper width="38%" tabletWidth="100%" tabletMargin="40px 0 0 0">
          <BlocksContent data={data.formText} />
        </TextWrapper>
      )}
      <JotformEmbed src={formUrl} />
    </StandardLayout>
  );
}

export default Form;
