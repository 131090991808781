import React from "react";
import styled from "styled-components";
import { Maybe, SanityContainer } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import Slices from "./slices";

interface Props {
  data: Maybe<SanityContainer>;
}

export default function ContainerBlock({ data }: Props) {
  if (!data) return null;
  const { title, backgroundColor, content } = data;

  const styles = {
    "--background-color": backgroundColor || "transparent",
  } as React.CSSProperties;

  return (
    <Container style={styles}>
      <div className="content">
        {title && <span className="title-span">{title}</span>}
        <Slices data={content} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 96%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 30px;
  background-color: var(--background-color);

  .title-span {
    padding: 7px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid ${colors.navy};
  }

  .content {
    text-align: center;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 80px 10px;
    overflow: hidden;

    .content {
      width: 90%;
      margin: auto;
    }
  }
`;
